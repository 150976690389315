<template>
    <div>
        <v-card>
            <v-card flat>
                <v-card-text>
                    <v-col md="9" :key="componentKey">
                        Um einen neuen Anwender hinzuzufügen, einfach eine E-Mail-Adresse
                        eingeben und auf "Absenden" drücken. Der neue Mitarbeiter erhält eine E-Mail mit einer Einladung in
                        das Portal.
                        <v-form ref="form">
                            <v-text-field label="E-Mail-Adresse" v-model="email" :rules="emailRules"></v-text-field>
                            <v-btn elevation="2" @click="sendInvitation()" :loading="loading" :disabled="loading">Anwender
                                einladen
                            </v-btn>
                        </v-form>
                    </v-col>
                </v-card-text>
            </v-card>
        </v-card>
    </div>
</template>

<script>
import { functions } from "@/plugins/firebase"
import { httpsCallable } from "firebase/functions"
export default {
    data() {
        return {
            componentKey: 0,
            top: true,
            showSuccess: false,
            showError: false,
            loading: false,
            email: null,
            emailRules: [
                (v) =>
                    !v ||
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    "E-Mail unvollständig oder inkorrekt"
            ],
        }
    },
    computed: {},
    watch: {
    },
    methods: {
        sendInvitation: async function () {
            this.loading = true
            const inviteMitarbeiter = httpsCallable(functions, "inviteMitarbeiter")
            const data = await inviteMitarbeiter({ email: this.email, uid: this.$store.state.auth.userprofile.sub })
            if (data.data == "OK") {
                this.$store.commit("showSnackbar", {
                    status: "succsess",
                    message: "Einladung erfolgreich versendet.",
                });
            } else if (data.data == "USER EXISTS") {
                this.$store.commit("showSnackbar", {
                    status: "warning",
                    message: "Für die Mailadresse existiert bereits eine Anmeldung.",
                })
            } else if (data.data == "ALREADY EXISTS") {
                this.$store.commit("showSnackbar", {
                    status: "warning",
                    message: "Für die Mailadresse existiert bereits eine Einladung.",
                })
            } else {
                this.$store.commit("showSnackbar", {
                    status: "error",
                    message: "Beim Versuch die Einladung zu versenden ist ein unbekannter Fehler aufgetreten.",
                });
            }
            this.loading = false;
            this.$refs.form.reset()
        },

    },
    mounted() {
        // SignIn Check
        if (!this.$store.state.auth.isSignedIn) {
            this.$router.push("/login")
            return
        }

    }
}
</script>

<style>
.custom-loader {
    animation: loader 1s infinite;
    display: flex;
}

@-moz-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@-o-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>