var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-col",
                    { key: _vm.componentKey, attrs: { md: "9" } },
                    [
                      _vm._v(
                        ' Um einen neuen Anwender hinzuzufügen, einfach eine E-Mail-Adresse eingeben und auf "Absenden" drücken. Der neue Mitarbeiter erhält eine E-Mail mit einer Einladung in das Portal. '
                      ),
                      _c(
                        "v-form",
                        { ref: "form" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "E-Mail-Adresse",
                              rules: _vm.emailRules,
                            },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                elevation: "2",
                                loading: _vm.loading,
                                disabled: _vm.loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.sendInvitation()
                                },
                              },
                            },
                            [_vm._v("Anwender einladen ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }